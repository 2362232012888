import React, { useState, useRef, useEffect } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar";
import AuthenticationContainer from "../container";
import Block from "../../../components/Tailwind/Block";
import GroundFormik from "../../../components/Tailwind/Form";
import { Value } from "../../../utils/types";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import images from "../../../images";
import Button from "../../../components/Tailwind/Button";
import LinkButton from "../../../components/Tailwind/LinkButton";
import IntlMessages from "../../../utils/messages";
import {
  getPasswordPolicy,
  getPasswordPolicyInfos,
} from "../../../utils/authentication";
import contextStore from "../../../redux/store";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import LocaleSwitch from "../../../containers/LocaleSwitch";

interface Props {
  intl: IntlShape;
  location: any;
}

const getMapUrlParams = (location: any) => {
  const params = new URLSearchParams(location.search);
  const search = decodeURIComponent(params.toString());
  const result = {};
  search.split("&").forEach(part => {
    const item = part.split("=");
    result[item[0]] = item[1];
  });

  return result;
};

const ValidatePassword = (props: Props) => {
  const ref = useRef(null);

  const { intl, location } = props;

  const schema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(intl.formatMessage({ id: "general.field.email.invalid" })),
    password: getPasswordPolicy(intl),
    confirmPassword: Yup.string()
      .trim()
      .when("password", {
        is: (val: string) => val && val.length > 0,
        then: () =>
          Yup.string().oneOf(
            [Yup.ref("password")],
            intl.formatMessage({ id: "page.validate.password.not.matching" })
          ),
      }),
  });

  const confirmPasswordAction = GroundAuthContextStore.useStoreActions(
    actions => actions.authentication.confirmPassword
  );

  const [loading, setLoading] = useState(false);
  const signIn = GroundAuthContextStore.useStoreActions(
    actions => actions.authentication.signIn
  );

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const setTokens = contextStore.useStoreActions(
    actions => actions.authentication.setTokens
  );

  const mapUrlParams = getMapUrlParams(location);
  const email = mapUrlParams["email"] || "";

  const values: Value[] = [
    {
      id: "email",
      name: "email",
      type: "email",
      label: "general.email.address",
      placeholder: "general.email.address",
      required: true,
      disabled: true,
      value: email,
      useIntl: true,
    },
    {
      id: "password",
      name: "password",
      type: "password",
      label: "general.new.password",
      placeholder: "general.password.placeholder",
      required: true,
      disabled: loading,
      value: "",
      useIntl: true,
      rows: [
        <div className="flex w-full items-center pt-2" key={0}>
          <img alt="" src={images.password} />
          <p className="pl-2 text-11px text-ground-gray-100 leading-5">
            {getPasswordPolicyInfos(intl)}
          </p>
        </div>,
      ],
    },
    {
      id: "confirmPassword",
      name: "confirmPassword",
      type: "password",
      label: "general.confirm.password",
      placeholder: "general.password.placeholder",
      required: true,
      disabled: loading,
      value: "",
      useIntl: true,
    },
    {
      id: "code",
      name: "code",
      type: "text",
      label: "page.validate.password.code",
      placeholder: "page.validate.password.code.placeholder",
      required: true,
      disabled: loading,
      value: "",
      useIntl: true,
    },
  ];

  useEffect(() => {
    if (me && process.env.REACT_APP_GOOGLE_ANALYTICS) {
      // google anaytics set user_role
      window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS, {
        user_id: me.id,
      });
      window.gtag("set", "user_properties", {
        user_role: me.role,
      });
    }
  }, [me]);

  const handleSubmit = val => {
    setLoading(true);

    if (ref.current) {
      ref.current?.continuousStart();

      confirmPasswordAction({
        confirmation_code: val.code.trim(),
        email: val.email.trim(),
        password: val.password.trim(),
      })
        .then(() => {
          setLoading(false);
          signIn({
            email: val.email.trim(),
            password: val.password.trim(),
          })
            .then(response => {
              setTokens(response.data);

              setLoading(false);
              ref.current?.complete();

              history.push(`/${EnumPaths.ROOT}`);
            })
            .catch(err => {
              setLoading(false);
              ref.current?.complete();

              const { data } = err.response;
              const code = data?.code || data?.error_code;
              const message = intl.formatMessage({
                id: intl.messages[`error.auth.${code}`]
                  ? `error.auth.${code}`
                  : "error.auth.InvalidParameterException",
              });
              toast(message, {
                type: "error",
              });
            });
        })
        .catch(err => {
          setLoading(false);
          ref.current?.complete();

          const { data } = err.response;
          const message = intl.formatMessage({
            id: intl.messages[`error.auth.${data?.error?.message}`]
              ? `error.auth.${data?.error?.message}`
              : "error.auth.default",
          });
          toast(message, {
            type: "error",
          });
        });
    }
  };

  return (
    <AuthenticationContainer>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.validate.password.document.title",
          })}
        </title>
      </Helmet>
      <LoadingBar height={5} color="#00D480" ref={ref} />
      <div className="flex justify-end pt-4 pr-4">
        <LocaleSwitch />
      </div>
      <div className="flex flex-col relative items-center h-screen justify-center">
        <div className="flex justify-center w-full sm:w-3/4 lg:w-5/12 cursor-pointer">
          <img
            alt=""
            src={images.ground}
            onClick={() => history.push(`/${EnumPaths.SIGNIN}`)}
          />
        </div>
        <div className="flex flex-col">
          <p className="pt-3 m-5 text-30px text-ground-black-100 text-center leading-5 font-semibold">
            <IntlMessages id="general.forgot.password" />
          </p>
          <p className="pb-3 text-14px text-ground-gray-100 text-center leading-6">
            <IntlMessages id="page.validate.password.subtitle" />
          </p>
        </div>
        <div className="w-full sm:w-3/4 lg:max-w-screen-sm">
          <Block>
            <div className="py-3 sm:py-5 flex" />
            <GroundFormik
              item={null}
              values={values}
              onSubmit={handleSubmit}
              alternate={false}
              validationSchema={schema}
              width=""
              className="lg:px-10"
            >
              <div
                key="validatepassword"
                className="flex flex-col px-6 items-center lg:px-10"
              >
                <Button
                  id="btn-validate-password"
                  name="btn-validate-password"
                  type="submit"
                  item={null}
                  disabled={loading}
                  className="w-full mt-5"
                >
                  <span className="text-center">
                    <IntlMessages id="general.submit" />
                  </span>
                </Button>
                <div className="pt-4 pb-10">
                  <LinkButton
                    id="link-back-validate-password"
                    name="link-back-validate-password"
                    label="general.cancel"
                    disabled={loading}
                    onClick={() => {
                      history.push(`/${EnumPaths.SIGNIN}`);
                    }}
                  />
                </div>
              </div>
            </GroundFormik>
          </Block>
        </div>
      </div>
    </AuthenticationContainer>
  );
};

export default injectIntl(ValidatePassword);
