import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import {
  CreatePaymentMethodOpts,
  ListPaymentMethodsOpts,
  ListUserPaymentMethodsOpts,
  PaymentMethod,
  UpdatePaymentMethodOpts,
} from "../../../models/Api/PaymentMethods";
import { IGetResponse } from "../../../models/Api";

export interface IListResponse<T> {
  data: {
    items: [T];
    total: number;
    has_more: boolean;
    next_url: string | null;
  };
  success: boolean;
}

export const listUserPaymentMethods = async (
  payload: ListUserPaymentMethodsOpts
): Promise<AxiosResponse<IListResponse<PaymentMethod>>> => {
  const params = {};
  if (
    payload.excludedFromBackOffice !== null &&
    payload.excludedFromBackOffice !== undefined
  ) {
    params["excluded_from_bo[eq]"] = payload.excludedFromBackOffice;
  }

  return axiosBackend.get(`/users/${payload.userId}/payment_methods`, {
    params,
  });
};

export const listPaymentMethods = async (
  payload: ListPaymentMethodsOpts
): Promise<AxiosResponse<IListResponse<PaymentMethod>>> => {
  const params = {
    offset: payload.offset,
    ...(payload.limit && {
      limit: payload.limit,
    }),
  };
  params["excluded_from_bo[eq]"] = payload.excludedFromBackOffice;

  return axiosBackend.get("/payment_methods", { params });
};

export const createPaymentMethod = async (
  payload: CreatePaymentMethodOpts
): Promise<AxiosResponse<IGetResponse<PaymentMethod>>> => {
  return axiosBackend.post("/payment_methods", payload);
};

export const getPaymentMethod = async (
  id: number
): Promise<AxiosResponse<IGetResponse<PaymentMethod>>> => {
  return axiosBackend.get(`/payment_methods/${id}`);
};

export const updatePaymentMethod = async (
  payload: UpdatePaymentMethodOpts
): Promise<AxiosResponse<IGetResponse<PaymentMethod>>> => {
  const { id, ...params } = payload;

  return axiosBackend.put(`/payment_methods/${id}`, params);
};

export const deletePaymentMethod = async (
  id: number
): Promise<AxiosResponse<IGetResponse<PaymentMethod>>> => {
  return axiosBackend.delete(`/payment_methods/${id}`);
};
