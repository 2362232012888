import { AxiosResponse } from "axios";
import {
  CreateCenterOpts,
  UpdateCenterOpts,
} from "./../../models/Center/index";
import { uploadPicturesPromise } from "../Image";
import {
  convertAttributeItems,
  convertAuthorizations,
  convertClosedDaysItems,
  convertDaySlotsItems,
  convertExceptionalOpeningHoursItems,
} from "../../../utils/preprocessing";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import axiosBackend from "../../../axiosBackend";

export const createCenter = async (payload: CreateCenterOpts) => {
  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.CENTER
  );

  const { urls } = response;

  const params = {
    ...payload.values,
    name: payload.values.name,
    description: payload.values.description,
    enabled: payload.enabled,
    markForDelete: false,
    pictures: urls,
  };

  return payload.callbacks.createCenter(params);
};

export const updateCenter = async (payload: UpdateCenterOpts) => {
  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.CENTER
  );

  const { urls } = response;

  if (!payload.center) throw new Error("payload.center must be defined");

  const params = {
    id: payload.center.id,
    pictures: urls,
    description: payload.center.description,
    name: payload.center.name,
    address: payload.center.address,
    city: payload.center.city,
    email: payload.center.email,
    phone: payload.center.phone,
    country: payload.center.country,
    zipCode: payload.center.zipCode,
    centerTypeId: payload.centerTypeId,
    location: {
      lat: payload.center.location?.lat,
      lon: payload.center.location?.lon,
    },
    ...(payload.daySlots &&
      payload.hoursRanges && {
        daySlots: convertDaySlotsItems(payload.daySlots, payload.hoursRanges),
      }),
    ...(payload.closedDays && {
      closedDays: convertClosedDaysItems(payload.closedDays),
    }),
    ...(payload.exceptionalOpeningHours && {
      exceptionalOpeningHours: convertExceptionalOpeningHoursItems(
        payload.exceptionalOpeningHours
      ),
    }),
    attributes: payload.attributes && convertAttributeItems(payload.attributes),
    authorizations:
      payload.authorizations && convertAuthorizations(payload.authorizations),
    centerOperatorId: payload.center.operator!.id,
  };

  return payload.callbacks.updateCenter(params);
};

/**
 * duplicate center
 *
 * @param id
 * @returns
 */
export const duplicateCenter = async (id: string): Promise<AxiosResponse> => {
  return axiosBackend.post(`/buildings/${id}/duplicate`);
};
