import { Buffer } from "buffer";
import { Image, ActionTypes } from "../../../utils/types";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import axiosInstance from "../../../axiosInstance";

window.Buffer = Buffer;

export function uploadPicturesPromise(
  pictures: Image[] | undefined,
  entity: EnumPermissionEntity
): Promise<{ urls: string[] | undefined }> {
  return uploadPictures(pictures, entity);
}

async function uploadPictures(
  pictures: Image[] | undefined,
  entity: EnumPermissionEntity
) {
  const picturesToKeep = pictures?.filter(
    e => e.action === ActionTypes.TO_KEEP
  );

  const urls = picturesToKeep?.length
    ? picturesToKeep?.map(el => el.picture as string)
    : [];

  const picturesToAdd = pictures?.filter(
    e => e.source === true && e.action === ActionTypes.TO_ADD && !!e.file
  );

  if (picturesToAdd && picturesToAdd.length > 0) {
    const folder = getBucketFolder(entity);
    const paths = await Promise.all(
      picturesToAdd.map(p => uploadPicture(p.file!, folder))
    );

    return { urls: urls.concat(paths) };
  } else {
    return { urls };
  }
}

async function uploadPicture(file: File, folder: string) {
  const formData = new FormData();
  formData.append("picture", file);
  formData.append("folder", folder);

  return axiosInstance.post("/image", formData).then(res => {
    return res.data.data as string;
  });
}

const EnumBucketFolders = {
  CENTER: "centers",
  EVENT: "events",
  NEWS: "news",
  PRODUCT: "products",
  PROVIDER: "providers",
  SERVICE: "services",
  USER: "users",
  LEAD: "leads",
};

const getBucketFolder = (entity: EnumPermissionEntity) => {
  switch (entity) {
    case EnumPermissionEntity.PRODUCT:
      return EnumBucketFolders.PRODUCT;
    case EnumPermissionEntity.CENTER:
      return EnumBucketFolders.CENTER;
    case EnumPermissionEntity.PROVIDER:
      return EnumBucketFolders.PROVIDER;
    case EnumPermissionEntity.SERVICE:
      return EnumBucketFolders.SERVICE;
    case EnumPermissionEntity.EVENT:
      return EnumBucketFolders.EVENT;
    case EnumPermissionEntity.NEWS:
      return EnumBucketFolders.NEWS;
    case EnumPermissionEntity.USER:
      return EnumBucketFolders.USER;
    default:
      return "";
  }
};
