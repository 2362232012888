import React, { useState, useRef, useEffect } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { Location } from "history";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import LoadingBar from "react-top-loading-bar";
import IntlMessages from "../../../utils/messages";
import AuthenticationContainer from "../container";
import Block from "../../../components/Tailwind/Block";
import GroundFormik from "../../../components/Tailwind/Form";
import { Value } from "../../../utils/types";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import images from "../../../images";
import Button from "../../../components/Tailwind/Button";
import LinkButton from "../../../components/Tailwind/LinkButton";
import {
  getPasswordPolicy,
  getPasswordPolicyInfos,
} from "../../../utils/authentication";
import contextStore from "../../../redux/store";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import LocaleSwitch from "../../../containers/LocaleSwitch";

interface Props {
  location: Location<{
    email: string;
    temporaryPassword: string;
  }>;
  intl: IntlShape;
}

const ChangePassword = ({ location, intl }: Props) => {
  const ref = useRef(null);

  const schema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(intl.formatMessage({ id: "general.field.email.invalid" })),
    newPassword: getPasswordPolicy(intl),
    confirmPassword: Yup.string()
      .trim()
      .when("newPassword", {
        is: (val: string) => val?.length > 0,
        then: () =>
          Yup.string().oneOf(
            [Yup.ref("newPassword")],
            intl.formatMessage({
              id: "page.validate.password.not.matching",
            })
          ),
      }),
  });

  const [loading, setLoading] = useState(false);
  const signIn = GroundAuthContextStore.useStoreActions(
    actions => actions.authentication.signIn
  );

  const setTokens = contextStore.useStoreActions(
    actions => actions.authentication.setTokens
  );

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const values: Value[] = [
    {
      id: "email",
      name: "email",
      type: "email",
      label: "general.email.address",
      placeholder: "general.email.address.placeholder",
      required: true,
      disabled: true,
      value: location.state.email,
      useIntl: true,
    },
    {
      id: "password",
      name: "password",
      type: "password",
      label: "page.change.password.temporary.password",
      placeholder: "page.change.password.temporary.password.placeholder",
      required: true,
      disabled: true,
      value: location.state.temporaryPassword,
      useIntl: true,
      hidden: true,
    },
    {
      id: "newPassword",
      name: "newPassword",
      type: "password",
      label: "general.new.password",
      placeholder: "general.password.placeholder",
      required: true,
      disabled: loading,
      value: "",
      useIntl: true,
      rows: [
        <div className="flex w-full items-center pt-2" key={0}>
          <img alt="" src={images.password} />
          <p className="pl-2 text-11px text-ground-gray-100 leading-5">
            {getPasswordPolicyInfos(intl)}
          </p>
        </div>,
      ],
    },
    {
      id: "confirmPassword",
      name: "confirmPassword",
      type: "password",
      label: "general.confirm.password",
      placeholder: "general.password.placeholder",
      required: true,
      disabled: loading,
      value: "",
      useIntl: true,
    },
  ];

  useEffect(() => {
    if (me && process.env.REACT_APP_GOOGLE_ANALYTICS) {
      // google anaytics set user_role
      window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS, {
        user_id: me.id,
      });
      window.gtag("set", "user_properties", {
        user_role: me.role,
      });
    }
  }, [me]);

  const handleSubmit = formValues => {
    setLoading(true);
    ref.current!.continuousStart();

    signIn({
      email: formValues.email.trim(),
      password: formValues.password.trim(),
      newPassword: formValues.newPassword.trim(),
    })
      .then(response => {
        setTokens(response.data);
        setLoading(false);
        ref.current!.complete();

        history.push(`/${EnumPaths.ROOT}`);
      })
      .catch(err => {
        setLoading(false);
        ref.current!.complete();

        const { data } = err.response;
        const code = data?.code || data?.error_code;
        const message = intl.formatMessage({ id: `error.auth.${code}` });
        toast(message, {
          type: "error",
        });
      });
  };

  return (
    <AuthenticationContainer>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.change.password.document.title",
          })}
        </title>
      </Helmet>
      <LoadingBar height={5} color="#00D480" ref={ref} />
      <div className="flex justify-end pt-4 pr-4">
        <LocaleSwitch />
      </div>
      <div className="flex flex-col relative items-center h-screen justify-center">
        <div className="flex justify-center w-full sm:w-3/4 lg:w-5/12 cursor-pointer">
          <img
            alt=""
            src={images.ground}
            onClick={() => history.push(`/${EnumPaths.SIGNIN}`)}
          />
        </div>
        <div className="flex flex-col">
          <p className="pt-3 m-5 text-30px text-ground-black-100 text-center leading-5 font-semibold">
            <IntlMessages id="page.change.password.title" />
          </p>
          <p className="pb-3 text-14px text-ground-gray-100 text-center leading-6">
            <IntlMessages id="page.change.password.subtitle" />
          </p>
        </div>
        <div className="w-full sm:w-3/4 lg:max-w-screen-sm">
          <Block>
            <div className="py-3 sm:py-5 flex" />
            <GroundFormik
              item={null}
              values={values}
              onSubmit={handleSubmit}
              alternate={false}
              validationSchema={schema}
              width=""
              className="lg:px-10"
            >
              <div
                key="changepassword"
                className="flex flex-col px-6 lg:px-10 items-center"
              >
                <Button
                  className="w-full mt-5"
                  id="btn-change-password"
                  name="btn-change-password"
                  type="submit"
                  item={null}
                  disabled={loading}
                >
                  <span className="text-center">
                    <IntlMessages id="general.submit" />
                  </span>
                </Button>
                <div className="pt-4 pb-10">
                  <LinkButton
                    id="link-back-change-password"
                    name="link-back-change-password"
                    label="general.cancel"
                    disabled={loading}
                    onClick={() => {
                      history.push(`/${EnumPaths.SIGNIN}`);
                    }}
                  />
                </div>
              </div>
            </GroundFormik>
          </Block>
        </div>
      </div>
    </AuthenticationContainer>
  );
};

export default injectIntl(ChangePassword);
