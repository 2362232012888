import React, { useState } from "react";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import Dropdown from "../../../../../components/Tailwind/Dropdown";
import history from "../../../../../history";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import {
  EnumChannel,
  EnumOrderStatus,
  EnumPermissionEntity,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import {
  UpdateOrderItemStatusOpts,
  UpdateOrderStatusOpts,
} from "../../../../../lib/ground-aws-graphql-core/models/Api/Order";
import { Order } from "../../../../../lib/ground-aws-graphql-core/models/Order";
import { OrderItem } from "../../../../../lib/ground-aws-graphql-core/models/OrderItem";
import IntlMessages from "../../../../../utils/messages";
import { EnumPaths } from "../../../../../utils/navigation";
import {
  getFormattedPrice,
  getUnitLabel,
} from "../../../../../utils/price-unit";
import { getTranslation } from "../../../../../utils/translation";
import DropDownStatus, {
  getCurrentStatus,
  getStatus,
  NextStatus,
} from "../order/status";
import { GlobalOrder } from "../../../../../lib/ground-aws-graphql-core/models/GlobalOrder";
import {
  getGlobalOrderTotal,
  getOrderItemTotal,
} from "../../../../../utils/globalOrder";
import FilterSelect from "../../../../../components/Tailwind/Filters/select";
import { retrieveUserFromOrder } from "../../../../../utils/user";
import { GroundAuthContextStore } from "../../../../../lib/ground-aws-cognito-auth-core";
import { EnumFilterFields, EnumFilterType } from "../../../../../utils/filter";
import {
  BodyElement,
  BodyRow,
  TableChangeParams,
} from "../../../../../components/Table/types";
import Table from "../../../../../components/Table";
import images from "../../../../../images";
import { getDataRelativeToOrderItem } from "../../../../../components/Tailwind/GridOrderItems/GridOrderItem";
import { Center } from "../../../../../lib/ground-aws-graphql-core/models/Center";
import { displayDayDDMMYYYY_HHMM } from "../../../../../utils/config";

export interface ILoadOptionsResponse {
  options: {
    id: string;
    code?: number;
    name: string;
  }[];
  hasMore: boolean;
  additional: {
    page: number;
  };
}

type Props = {
  center: Center;
  globalOrders: GlobalOrder[] | null;
  total: number | null | undefined;
  onChange: (params: TableChangeParams) => void;
  loadOptions?: {
    [key: string]: (
      searchQuery: any,
      _loadedOptions: any,
      { page }: { page: number }
    ) => Promise<ILoadOptionsResponse>;
  };
  setRefresh: (refresh: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  intl: IntlShape;
  match: Match<{ cid: string }>;
};

const GlobalOrdersTable = (props: Props) => {
  const {
    center,
    globalOrders,
    total,
    onChange,
    loadOptions,
    setRefresh,
    loading,
    setLoading,
    intl,
    match,
  } = props;

  const [globalOrdersOpen, setGlobalOrdersOpen] = useState<string[]>([]);

  const updateOrderStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.blOrder.updateOrderStatus
  );

  const updateOrderItemStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.blOrder.updateOrderItemStatus
  );

  const cancelOrder = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cancellation.cancelOrder
  );

  const cancelOrderItem = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cancellation.cancelOrderItem
  );

  const meDetails = GroundAuthContextStore.useStoreState(
    state => state.authentication.meDetails
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const handleUpdateStatus = (
    globalOrder: GlobalOrder,
    order: Order,
    orderItem: OrderItem | null | undefined,
    orderStatus: EnumOrderStatus
  ) => {
    setLoading(true);

    if (orderStatus === EnumOrderStatus.CANCELED) {
      if (globalOrder) {
        if (orderItem && orderItem.id) {
          // cancel order item
          cancelOrderItem({
            globalOrderId: globalOrder.id,
            orderId: order.id,
            orderItemId: orderItem.id,
          })
            .then(() => {
              toast(
                intl.formatMessage({
                  id: "page.order.update.order.success",
                }),
                { type: "success" }
              );
              setRefresh(true);
            })
            .catch(() => {
              toast(
                intl.formatMessage({
                  id: "page.order.update.order.error",
                }),
                {
                  type: "error",
                }
              );
              setLoading(false);
            });
        } else {
          // cancel order
          cancelOrder({
            globalOrderId: globalOrder.id,
            orderId: order.id,
          })
            .then(() => {
              toast(
                intl.formatMessage({
                  id: "page.order.update.order.success",
                }),
                { type: "success" }
              );
              setRefresh(true);
            })
            .catch(() => {
              toast(
                intl.formatMessage({
                  id: "page.order.update.order.error",
                }),
                {
                  type: "error",
                }
              );
              setLoading(false);
            });
        }
      }
    } else if (orderItem && orderItem.id) {
      const params: UpdateOrderItemStatusOpts = {
        globalOrderId: globalOrder.id,
        orderId: order.id,
        orderItemId: orderItem.id,
        status: orderStatus,
      };
      updateOrderItemStatus(params)
        .then(() => {
          toast(
            intl.formatMessage({
              id: "page.order.update.order.success",
            }),
            { type: "success" }
          );
          setRefresh(true);
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.order.update.order.error",
            }),
            {
              type: "error",
            }
          );
          setLoading(false);
        });
    } else {
      const params: UpdateOrderStatusOpts = {
        globalOrderId: globalOrder.id,
        orderId: order.id,
        status: orderStatus,
      };
      updateOrderStatus(params)
        .then(() => {
          toast(
            intl.formatMessage({
              id: "page.order.update.order.success",
            }),
            { type: "success" }
          );
          setRefresh(true);
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.order.update.order.error",
            }),
            {
              type: "error",
            }
          );
          setLoading(false);
        });
    }
  };

  const ordersTableHead = [
    "page.list.orders.table.head.name",
    "general.date",
    "general.client",
    "general.company",
    "general.provider",
    "general.status",
    "general.total.ttc",
    "general.actions",
  ];

  const ordersTableBody = (center: Center) =>
    globalOrders?.flatMap(globalOrder => {
      const {
        timeplaced,
        id,
        user,
        metadata,
        channel,
        ormOrder,
        status,
        price,
        totalTax,
        currency,
      } = globalOrder;

      const invoice = globalOrder.orders.items.find(o => !!o.invoice)?.invoice;
      const invoiceMetadata = invoice?.metadata
        ? JSON.parse(invoice?.metadata)
        : null;
      const companyName = invoiceMetadata?.b2b
        ? invoiceMetadata?.b2b.socialReason
        : "";

      const globalOrderDetailsLink = `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.GLOBAL_ORDERS}/${id}`;

      const dropdownValues = [
        {
          id: "global_order_details",
          label: "general.show.details",
          icon: images.details,
          link: globalOrderDetailsLink,
        },
      ];

      const { client } = retrieveUserFromOrder(user, metadata, channel);

      const toggleOpenGlobalOrder = () => {
        let newGlobalOrdersOpen = [...globalOrdersOpen];
        if (newGlobalOrdersOpen.find(openElId => openElId === id)) {
          newGlobalOrdersOpen = newGlobalOrdersOpen.filter(
            openElId => openElId !== id
          );
        } else {
          newGlobalOrdersOpen.push(id);
        }

        setGlobalOrdersOpen(newGlobalOrdersOpen);
      };

      const openGlobalOrder = globalOrders.find(
        openEl => openEl.id === id && globalOrdersOpen.includes(id)
      );

      const NbProviderByCol = 2;
      const formatProviderCol = (orders: Order[], currentIndex: number) => {
        const remain = orders.length - NbProviderByCol;

        return orders.length >= NbProviderByCol
          ? currentIndex === NbProviderByCol - 1
            ? remain > 0
              ? `(+${remain})`
              : ""
            : ","
          : "";
      };

      const current = getCurrentStatus(intl, status);
      const payment = globalOrder.orders.items.find(o => !!o.payment)?.payment;
      const paymentMethodDetails = payment?.paymentMethodDetails;
      const json = paymentMethodDetails ? JSON.parse(paymentMethodDetails) : {};
      const { type } = json;
      const paymentOnInvoice = type === "invoice";

      const totalAmount = getGlobalOrderTotal(globalOrder);
      const options = getStatus(intl, status);

      // free case
      if (
        totalAmount <= 0 ||
        (paymentOnInvoice && !meDetails?.operator.payment_externalized) ||
        ![EnumChannel.MOBILE, EnumChannel.WEB].includes(channel)
      ) {
        if (current && current.value === EnumOrderStatus.PAID) {
          current.label = intl.formatMessage({ id: "page.order.confirmed" });
        }

        const nextStatus = options.nextStatus.reduce(
          (acc: NextStatus[], el: NextStatus) => {
            if (
              el.value !== EnumOrderStatus.REFUNDED &&
              el.value !== EnumOrderStatus.REFUND_REQUESTED
            ) {
              const newEl = { ...el };
              if (el.value === EnumOrderStatus.PAID) {
                newEl.label = intl.formatMessage({
                  id: "page.order.confirmed",
                });
              }
              acc.push(newEl);
            }

            return acc;
          },
          []
        );

        options.nextStatus = nextStatus;
      }

      // must be after (free case treatment)
      const field = {
        value: EnumFilterFields[EnumFilterFields.STATUS],
        type: EnumFilterType.SELECT,
        async: false,
        options: options.nextStatus.map(o => ({
          ...o,
          value: `${o.value}`,
        })),
      };

      const globalOrderElements: BodyElement[] = [
        {
          element: (
            <div className="flex items-center">
              <button
                className="flex h-8 w-8 items-center justify-center"
                onClick={toggleOpenGlobalOrder}
                data-cy={`global-order-toggle-${id}`}
                type="button"
              >
                <span className="text-2xl leading-5 text-ground-blue-100">
                  {openGlobalOrder ? "-" : "+"}
                </span>
              </button>
              <span>{ormOrder}</span>
            </div>
          ),
        },
        {
          element: displayDayDDMMYYYY_HHMM(timeplaced, centerTimezone),
          onCellClick: () => history.push(globalOrderDetailsLink),
        },
        {
          element: client,
          onCellClick: () => history.push(globalOrderDetailsLink),
        },
        {
          element: companyName,
          onCellClick: () => history.push(globalOrderDetailsLink),
        },
        {
          element: (
            <ul>
              {globalOrder.orders.items
                .slice(0, NbProviderByCol)
                .map((order, i) => (
                  <li key={i}>
                    {getTranslation(order.provider.name)}{" "}
                    {formatProviderCol(globalOrder.orders.items, i)}
                  </li>
                ))}
            </ul>
          ),
          onCellClick: () => history.push(globalOrderDetailsLink),
        },
        {
          element: (
            <div
              className="flex items-center cursor-pointer"
              onClick={toggleOpenGlobalOrder}
            >
              <span>
                <IntlMessages id="page.list.orders.view.status" />
              </span>
            </div>
          ),
        },
        {
          element:
            getGlobalOrderTotal(globalOrder) > 0 ? (
              getFormattedPrice(price + totalTax, currency)
            ) : (
              <IntlMessages id="general.free" />
            ),
          onCellClick: () => history.push(globalOrderDetailsLink),
        },
        {
          element: (
            <Dropdown
              values={dropdownValues}
              dataCy={`global-order-dropdown-${id}`}
            />
          ),
        },
      ];

      const row: BodyRow[] = [
        {
          rowElements: globalOrderElements,
        },
      ];

      if (openGlobalOrder && openGlobalOrder.orders.items?.length > 0) {
        openGlobalOrder.orders.items.forEach(order => {
          const orderDetailsLink = `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.ORDERS}/${order.id}`;
          const rowTmp: Array<{ rowElements: BodyElement[] }> = [];

          order.orderItems.items.forEach(oi => {
            const { booking } = getDataRelativeToOrderItem(center, oi);

            const orderItemElements = [
              {
                element: (
                  <div className="flex flex-col items-start">
                    <span>
                      {getTranslation(oi.name)}
                      {oi.relatedOrderItemId && (
                        <span className="text-ground-gray-100">
                          {" "}
                          (<IntlMessages id="page.list.products.badge.option" />
                          )
                        </span>
                      )}
                    </span>
                    {booking && !booking.sameDay && (
                      <>
                        <span className="text-ground-gray-100">
                          <IntlMessages id="general.from" /> {booking.startDay}{" "}
                          <IntlMessages id="general.at" /> {booking.startHour}
                        </span>
                        <span className="text-ground-gray-100">
                          <IntlMessages id="general.to" /> {booking.endDay}{" "}
                          <IntlMessages id="general.at" /> {booking.endHour}
                        </span>
                      </>
                    )}
                    {booking && booking.sameDay && (
                      <div className="flex text-ground-gray-100">
                        {booking.startDay} - {booking.startHour}
                        {" > "}
                        {booking.endHour}
                      </div>
                    )}
                  </div>
                ),

                additionalClassName: "bg-ground-white-200",
                onCellClick: () => history.push(globalOrderDetailsLink),
              },
              {
                element: undefined,
                additionalClassName: "bg-ground-white-200",
                onCellClick: () => history.push(globalOrderDetailsLink),
              },
              {
                element: (
                  <span>
                    {oi.quantity} <IntlMessages id="general.pers" /> x{" "}
                    {oi.unitQuantity} {getUnitLabel(intl, oi.unit)}
                  </span>
                ),
                additionalClassName: "bg-ground-white-200",
                onCellClick: () => history.push(globalOrderDetailsLink),
              },
              {
                element: undefined,
                additionalClassName: "bg-ground-white-200",
                onCellClick: () => history.push(globalOrderDetailsLink),
              },
              {
                element: getTranslation(order.provider.name),
                additionalClassName: "bg-ground-white-200",
                onCellClick: () => history.push(globalOrderDetailsLink),
              },
              {
                element: (
                  <DropDownStatus
                    operator={meDetails?.operator}
                    order={order}
                    orderItem={oi}
                    status={oi.status}
                    displayRefundReason={false}
                    onChangeOrderStatus={e => {
                      if (
                        e.value !== EnumOrderStatus.REFUNDED &&
                        e.value !== EnumOrderStatus.REFUND_REQUESTED
                      ) {
                        handleUpdateStatus(openGlobalOrder, order, oi, e.value);
                      } else {
                        history.push(globalOrderDetailsLink);
                      }
                    }}
                    className="items-start"
                  />
                ),
                additionalClassName: "bg-ground-white-200",
              },
              {
                element:
                  getOrderItemTotal(oi) > 0 ? (
                    getFormattedPrice(oi.totalPrice + oi.totalTax, oi.currency)
                  ) : (
                    <IntlMessages id="general.free" />
                  ),
                additionalClassName: "bg-ground-white-200",
                onCellClick: () => history.push(globalOrderDetailsLink),
              },
              {
                element: undefined,
                additionalClassName: "bg-ground-white-200",
                onCellClick: () => history.push(globalOrderDetailsLink),
              },
            ];

            rowTmp.push({
              rowElements: orderItemElements,
            });
          });
          const orderGroupLine = [
            {
              element: (
                <span style={{ color: "#fff" }} className="hover:underline">
                  <IntlMessages id="page.list.orders.table.head.name" /> #
                  {order.ormOrder}
                </span>
              ),
              colSpan: rowTmp[0].rowElements.length,
              additionalClassName: "text-white bg-primary-800 py-1 text-8px",
              onCellClick: () => history.push(orderDetailsLink),
            },
          ];
          row.push({ rowElements: orderGroupLine }, ...rowTmp);
        });
      }

      return row;
    });

  return (
    <Table
      head={ordersTableHead}
      body={ordersTableBody(center)}
      noDataText="page.list.orders.empty"
      paginationTotal={total}
      permissionEntity={EnumPermissionEntity.GLOBAL_ORDER}
      onChange={onChange}
      loadOptions={loadOptions}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default injectIntl(GlobalOrdersTable);
