import React, { Fragment, useContext, useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { Popover, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { match as Match } from "react-router-dom";
import { ThunkCreator } from "easy-peasy";
import IntlMessages from "../../utils/messages";
import ConfirmModal from "../../utils/modal/confirm";
import Table from "../Table";
import { BodyRow, BodyElement } from "../Table/types";
import { OrderItem } from "../../lib/ground-aws-graphql-core/models/OrderItem";
import { Order } from "../../lib/ground-aws-graphql-core/models/Order";
import { getFormattedPrice, getUnitLabel } from "../../utils/price-unit";
import { getTranslation } from "../../utils/translation";
import {
  renderOrderItemNameCell,
  getDataRelativeToOrderItem,
} from "../Tailwind/GridOrderItems/GridOrderItem";
import { GlobalOrder } from "../../lib/ground-aws-graphql-core/models/GlobalOrder";
import { GroundGraphqlContextStore } from "../../lib/ground-aws-graphql-core";
import GridOrderItems from "../Tailwind/GridOrderItems";
import {
  EnumCurrency,
  EnumOrderStatus,
} from "../../lib/ground-aws-graphql-core/api/graphql/types";
import {
  UpdateOrderItemStatusOpts,
  UpdateOrderStatusOpts,
} from "../../lib/ground-aws-graphql-core/models/Api/Order";
import {
  EnumWebhookEventStatus,
  RefundRequest,
  RefundRequestEvent,
  RefundRequestEventAttempt,
  RefundRequestOrderItem,
} from "../../lib/ground-aws-graphql-core/models/Api/RefundRequests";
import { ThemeContext } from "../../containers/App";
import { EnumRefundReason } from "../../utils/order";
import "react-circular-progressbar/dist/styles.css";
import {
  CancelOrderItemOpts,
  CancelOrderOpts,
} from "../../lib/ground-aws-graphql-core/models/Api/Cancellation";
import { GroundAuthContextStore } from "../../lib/ground-aws-cognito-auth-core";
import { Center } from "../../lib/ground-aws-graphql-core/models/Center";
import {
  displayDayHH,
  displayDayDDMMYYYY_HHMM,
  getDuration,
  isBefore,
  isInPast,
} from "../../utils/config";
import {
  CreateRefundExternalizedOpts,
  RefundOpts,
} from "../../lib/ground-aws-graphql-core/models/Api/Refund";

interface Props {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  globalOrder: GlobalOrder;
  orders: Order[];
  showOrderHeader?: boolean;
  orderDataToRefund?: DataToRefund | null;
  match?: Match<{ cid: string; id: string }>;
}

export type DataToRefund = {
  orderToRefund: Order;
  orderItem: OrderItem | null | undefined;
  reason: EnumRefundReason;
};

type RetryRefundData = {
  event: RefundRequestEvent;
  attempt: RefundRequestEventAttempt;
  refundRequest: RefundRequest;
};

const TIME_BEFORE_UPDATE = 60;
const MAX_ATTEMPTS = 5;

export const handleUpdateStatus = (
  orderToUpdate: Order,
  orderItem: OrderItem | null | undefined,
  status: EnumOrderStatus,
  globalOrder: GlobalOrder,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>,
  cancelOrderItem: ThunkCreator<CancelOrderItemOpts, any>,
  cancelOrder: ThunkCreator<CancelOrderOpts, any>,
  updateOrderStatus: ThunkCreator<UpdateOrderStatusOpts, any>,
  updateOrderItemStatus: ThunkCreator<UpdateOrderItemStatusOpts, any>,
  intl: IntlShape
): void => {
  setLoading(true);

  if (status === EnumOrderStatus.CANCELED) {
    if (globalOrder) {
      if (orderItem && orderItem.id) {
        // cancel order item
        cancelOrderItem({
          globalOrderId: globalOrder.id,
          orderId: orderToUpdate.id,
          orderItemId: orderItem.id,
        })
          .then(response => {
            if (response.data.success) {
              toast(
                intl.formatMessage({ id: "page.order.update.order.success" }),
                { type: "success" }
              );
              setRefresh(true);
            } else {
              toast(
                intl.formatMessage({ id: "page.order.update.order.error" }),
                { type: "success" }
              );
              setLoading(false);
            }
          })
          .catch(() => {
            toast(intl.formatMessage({ id: "page.order.update.order.error" }), {
              type: "error",
            });
            setLoading(false);
          });
      } else {
        // cancel order
        cancelOrder({
          globalOrderId: globalOrder.id,
          orderId: orderToUpdate.id,
        })
          .then(response => {
            if (response.data.success) {
              toast(
                intl.formatMessage({ id: "page.order.update.order.success" }),
                { type: "success" }
              );
              setRefresh(true);
            } else {
              toast(
                intl.formatMessage({ id: "page.order.update.order.error" }),
                { type: "error" }
              );
              setLoading(false);
            }
          })
          .catch(() => {
            toast(intl.formatMessage({ id: "page.order.update.order.error" }), {
              type: "error",
            });
            setLoading(false);
          });
      }
    }
  } else if (orderItem && orderItem.id) {
    const params: UpdateOrderItemStatusOpts = {
      globalOrderId: globalOrder.id,
      orderId: orderToUpdate.id,
      orderItemId: orderItem.id,
      status,
    };
    updateOrderItemStatus(params)
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.order.update.order.success",
          }),
          { type: "success" }
        );
        setRefresh(true);
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.order.update.order.error",
          }),
          { type: "error" }
        );
        setLoading(false);
      });
  } else {
    const params: UpdateOrderStatusOpts = {
      globalOrderId: globalOrder.id,
      orderId: orderToUpdate.id,
      status,
    };
    updateOrderStatus(params)
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.order.update.order.success",
          }),
          { type: "success" }
        );
        setRefresh(true);
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.order.update.order.error",
          }),
          { type: "error" }
        );
        setLoading(false);
      });
  }
};

const GridOrderItemsAndRefunds = (props: Props): JSX.Element => {
  const {
    setLoading,
    setRefresh,
    globalOrder,
    orders,
    showOrderHeader,
    orderDataToRefund,
    match,
  } = props;

  const intl = useIntl();

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const [isHovering, setIsHovering] = useState(false);
  const [circularProgress, setCircularProgress] = useState(100);
  const [retryTimeRemaining, setRetryTimeRemaining] = useState("");
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isRetryRefundModalOpen, setIsRetryRefundModalOpen] = useState(false);
  const [dataToRefund, setDataToRefund] = useState<DataToRefund | null>();
  const [retryRefundData, setRetryRefundData] =
    useState<RetryRefundData | null>();

  const theme = useContext(ThemeContext);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right-start",
  });

  const updateOrderStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.blOrder.updateOrderStatus
  );

  const updateOrderItemStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.blOrder.updateOrderItemStatus
  );

  const cancelOrder = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cancellation.cancelOrder
  );

  const cancelOrderItem = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cancellation.cancelOrderItem
  );

  const retryRefundExternalized = GroundGraphqlContextStore.useStoreActions(
    actions => actions.refunds.retryRefundExternalized
  );

  const createRefund = GroundGraphqlContextStore.useStoreActions(
    actions => actions.refunds.createRefund
  );

  const createRefundExternalized = GroundGraphqlContextStore.useStoreActions(
    actions => actions.refunds.createRefundExternalized
  );

  const listRefundRequests = GroundGraphqlContextStore.useStoreActions(
    actions => actions.refundRequests.listRefundRequests
  );

  const refundRequests = GroundGraphqlContextStore.useStoreState(
    state => state.refundRequests.refundRequests
  );

  const meDetails = GroundAuthContextStore.useStoreState(
    state => state.authentication.meDetails
  );

  const center = GroundGraphqlContextStore.useStoreState(
    state => state.center.center
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  useEffect(() => {
    if (globalOrder) listRefundRequests({ globalOrderId: globalOrder.id });
  }, [globalOrder]);

  useEffect(() => {
    if (orderDataToRefund) {
      setDataToRefund(orderDataToRefund);
      setIsRefundModalOpen(true);
    }
  }, [orderDataToRefund]);

  const closeModal = () => {
    setDataToRefund(null);
    if (isRefundModalOpen) setIsRefundModalOpen(!isRefundModalOpen);
    if (isRetryRefundModalOpen)
      setIsRetryRefundModalOpen(!isRetryRefundModalOpen);
  };

  const displayRefundModalContent = () => {
    const orderItemsTableHead = [
      "",
      "general.provider",
      "page.order.table.head.quantity",
      "general.total.ht",
      "general.vat",
      "general.total.ttc",
    ];

    const orderItemsTableBody: BodyRow[] = [];

    // order item body
    const getBodyElement = (
      center: Center,
      order: Order,
      orderItem: OrderItem
    ) => {
      const { orderItemVat, orderItemTotal, booking } =
        getDataRelativeToOrderItem(center, orderItem);

      const {
        name,
        relatedOrderItemId,
        quantity,
        unitQuantity,
        unit,
        currency,
        totalPrice,
        totalTax,
      } = orderItem;

      const bodyElements: BodyElement[] = [
        {
          element: renderOrderItemNameCell(name, relatedOrderItemId, booking),
        },
        {
          element: getTranslation(order.provider.name),
        },
        {
          element: (
            <span>
              {quantity} <IntlMessages id="general.pers" /> x {unitQuantity}{" "}
              {getUnitLabel(intl, unit)}
            </span>
          ),
        },
        {
          element:
            orderItemTotal > 0 ? getFormattedPrice(totalPrice, currency) : null,
        },
        {
          element:
            orderItemTotal > 0
              ? `${getFormattedPrice(totalTax, currency)} (${orderItemVat} %)`
              : null,
        },
        {
          element:
            orderItemTotal > 0 ? (
              getFormattedPrice(totalPrice + totalTax, currency)
            ) : (
              <IntlMessages id="general.free" />
            ),
        },
      ];

      return bodyElements;
    };

    if (isRefundModalOpen && center) {
      if (dataToRefund?.orderItem?.relatedOrderItemId) {
        // The orderItem to refund is an option
        orderItemsTableBody.push({
          rowElements: getBodyElement(
            center,
            dataToRefund.orderToRefund,
            dataToRefund.orderItem
          ),
        });
      } else if (dataToRefund && !dataToRefund.orderItem && globalOrder) {
        // The whole order needs to be refunded
        dataToRefund.orderToRefund.orderItems.items.forEach(oi => {
          orderItemsTableBody.push({
            rowElements: getBodyElement(center, dataToRefund.orderToRefund, oi),
          });

          if (!oi.relatedOrderItemId) {
            globalOrder.orders.items.forEach(order => {
              order.orderItems.items.forEach(orderItem => {
                if (
                  orderItem.relatedOrderItemId === oi.id &&
                  order.id !== dataToRefund.orderToRefund.id
                ) {
                  orderItemsTableBody.push({
                    rowElements: getBodyElement(center, order, orderItem),
                  });
                }
              });
            });
          }
        });
      } else if (dataToRefund?.orderItem && globalOrder) {
        // Refund the orderItem and its relatedOrderItemId
        orderItemsTableBody.push({
          rowElements: getBodyElement(
            center,
            dataToRefund.orderToRefund,
            dataToRefund.orderItem
          ),
        });

        globalOrder.orders.items.forEach(order => {
          order.orderItems.items.forEach(orderItem => {
            if (orderItem.relatedOrderItemId === dataToRefund.orderItem?.id) {
              orderItemsTableBody.push({
                rowElements: getBodyElement(center, order, orderItem),
              });
            }
          });
        });
      }
    } else if (
      isRetryRefundModalOpen &&
      retryRefundData &&
      globalOrder &&
      center
    ) {
      const { refundRequest } = retryRefundData;

      const orderItemsToRefund = refundRequest.order_items.reduce(
        (orderItems: { orderItem: OrderItem; order: Order }[], oi) => {
          for (const order of globalOrder.orders.items) {
            const orderItemFound = order.orderItems.items.find(
              orderItem => oi.id.toString() === orderItem.id
            );

            if (orderItemFound) {
              orderItems.push({ orderItem: orderItemFound, order });
              break;
            }
          }

          return orderItems;
        },
        []
      );

      // Space first and options last (if there is a space)
      orderItemsToRefund.sort(oi1 =>
        !oi1.orderItem.relatedOrderItemId ? 1 : 0
      );

      const rowElementsArray: BodyRow[] = orderItemsToRefund.map(oi => ({
        rowElements: getBodyElement(center, oi.order, oi.orderItem),
      }));
      orderItemsTableBody.push(...rowElementsArray);
    }

    return (
      <Table
        head={orderItemsTableHead}
        body={orderItemsTableBody}
        noDataText=""
      />
    );
  };

  const handleRefund = () => {
    if (dataToRefund) {
      const { orderToRefund, orderItem, reason } = dataToRefund;

      if (meDetails?.operator?.payment_externalized) {
        if (globalOrder) {
          setLoading(true);
          const params: CreateRefundExternalizedOpts = {
            globalOrderId: globalOrder.id,
            orderId: orderToRefund.id,
            reason,
            ...(orderItem &&
              orderItem.id && {
                orderItemId: orderItem.id,
              }),
          };

          createRefundExternalized(params)
            .then(() => {
              toast(
                intl.formatMessage({
                  id: "page.order.create.refund.success",
                }),
                { type: "success" }
              );

              setRefresh(true);
            })
            .catch(() => {
              toast(
                intl.formatMessage({
                  id: "page.order.create.refund.error",
                }),
                { type: "error" }
              );
              setLoading(false);
            });
        }
      } else {
        setLoading(true);
        const params: RefundOpts = {
          orderId: orderToRefund.id,
          ...(orderItem &&
            orderItem.id && {
              orderItemId: orderItem.id,
            }),
          ...(reason && {
            reason,
          }),
        };

        createRefund(params)
          .then(() => {
            toast(
              intl.formatMessage({
                id: "page.order.create.refund.success",
              }),
              { type: "success" }
            );

            setRefresh(true);
          })
          .catch(() => {
            toast(
              intl.formatMessage({
                id: "page.order.create.refund.error",
              }),
              { type: "error" }
            );
            setLoading(false);
          });
      }
    }

    closeModal();
  };

  const retryRefund = async () => {
    if (!retryRefundData) return;

    setLoading(true);

    const { event, attempt } = retryRefundData;
    try {
      await retryRefundExternalized({ id: event.id });
    } catch (error: any) {
      const { data } = error.response;
      if (center) {
        const message = intl.formatMessage(
          {
            id: intl.messages[`error.refund.${data?.error?.message}`]
              ? `error.refund.${data?.error?.message}`
              : "page.error.default.message",
          },
          { date: displayDayHH(attempt.next_attempt_date, centerTimezone) }
        );
        toast(message, { type: "error" });
      }
    } finally {
      setLoading(false);
    }

    closeModal();
  };

  const showPopover = (nextAttemptDate: string) => {
    const { minutes, seconds } = getDuration(nextAttemptDate);

    setCircularProgress(((TIME_BEFORE_UPDATE - minutes) / 60) * 100);
    setRetryTimeRemaining(`${minutes}:${seconds}`);
    setIsHovering(true);
  };

  const sortAttempts = (
    a1: RefundRequestEventAttempt,
    a2: RefundRequestEventAttempt
  ) => (isBefore(a1.next_attempt_date, a2.next_attempt_date) ? 1 : -1);

  const refundRequestsTableHead = [
    "general.refund",
    "general.reason",
    "general.total.ht",
    "general.vat",
    "general.total.ttc",
    "general.date",
    "general.state",
  ];

  const findOrderItem = (
    go: GlobalOrder | null,
    refundOrderItem: RefundRequestOrderItem
  ): OrderItem | undefined => {
    let refundedOrderItem: OrderItem | undefined;
    if (go?.orders.items.length) {
      for (let i = 0; i < go?.orders?.items?.length; i++) {
        const order = go?.orders.items[i];

        if (order) {
          refundedOrderItem = order.orderItems.items.find(
            orderItem => orderItem.id === refundOrderItem.id.toString()
          );

          if (refundedOrderItem) break;
        }
      }
    }

    return refundedOrderItem;
  };

  const refundRequestsTableBody = (center: Center) =>
    refundRequests.items.map((refundRequest: RefundRequest, index) => {
      const refundRequestElements: BodyElement[] = [
        {
          element: (
            <div
              className="flex flex-col items-start space-y-2"
              key={`refund_request_${index}`}
            >
              {refundRequest.order_items.map((oi, index) => {
                const refundedOrderItem = findOrderItem(globalOrder, oi);

                if (!refundedOrderItem) return null;

                const { name, relatedOrderItemId } = refundedOrderItem;

                const { booking } = getDataRelativeToOrderItem(
                  center,
                  refundedOrderItem
                );

                return (
                  <Fragment key={`refund_request_order_item_${index}`}>
                    {renderOrderItemNameCell(name, relatedOrderItemId, booking)}
                  </Fragment>
                );
              })}
            </div>
          ),
        },
        {
          element: (
            <span>
              {refundRequest.reason && (
                <>
                  {intl.formatMessage({
                    id: `page.order.refund.reason.${refundRequest.reason}`,
                  })}
                </>
              )}
            </span>
          ),
        },
        {
          element: getFormattedPrice(
            refundRequest.total_price,
            EnumCurrency.EUR
          ),
        },
        {
          element: getFormattedPrice(refundRequest.total_tax, EnumCurrency.EUR),
        },
        {
          element: getFormattedPrice(refundRequest.amount, EnumCurrency.EUR),
        },
        {
          element: (
            <div className="flex flex-col items-center space-y-2">
              {refundRequest.events.map(event => (
                <Fragment key={event.id}>
                  {event.attempts.map((a, index) => (
                    <span key={`webhook_attempts_${index}`}>
                      {displayDayDDMMYYYY_HHMM(a.created_at, centerTimezone)}
                    </span>
                  ))}
                </Fragment>
              ))}

              <span>
                {displayDayDDMMYYYY_HHMM(
                  refundRequest.created_at,
                  centerTimezone
                )}
              </span>
            </div>
          ),
        },
        {
          element: (
            <div className="flex flex-col space-y-2">
              {refundRequest.events.map(event => (
                <Fragment key={event.id}>
                  {event.attempts.sort(sortAttempts).map((attempt, index) => {
                    const canRetryRefund =
                      meDetails?.operator?.payment_externalized &&
                      attempt.status === EnumWebhookEventStatus.fail &&
                      index === 0 &&
                      event.attempts.length < MAX_ATTEMPTS;

                    const retryRefundAvailable = isInPast(
                      attempt.next_attempt_date
                    );

                    return (
                      <div
                        key={`attempt-${attempt.id}`}
                        className="flex justify-between items-center"
                      >
                        <div className="flex space-x-2 items-end">
                          <span
                            className={
                              attempt.status === EnumWebhookEventStatus.fail
                                ? "text-red-500"
                                : "text-green-500"
                            }
                          >
                            <IntlMessages
                              id={`page.order.refund.request.status.${attempt.status}`}
                            />
                          </span>

                          {canRetryRefund && !retryRefundAvailable && (
                            <Popover className="relative">
                              <div
                                ref={referenceElement}
                                className="flex items-center justify-center bg-gray-200 text-neutral-900 rounded-full w-3 h-3 text-8px cursor-pointer"
                                onMouseEnter={() =>
                                  showPopover(attempt.next_attempt_date)
                                }
                                onMouseLeave={() => setIsHovering(false)}
                              >
                                i
                              </div>

                              <Transition
                                show={isHovering}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                              >
                                <Popover.Panel
                                  ref={popperElement}
                                  static
                                  style={styles.popper}
                                  {...attributes.popper}
                                >
                                  <div className="flex flex-col items-center space-y-1 bg-gray-200 p-2 rounded-md text-xs text-ground-gray-300">
                                    <IntlMessages id="page.order.refund.request.retry.time" />

                                    <CircularProgressbar
                                      value={circularProgress}
                                      strokeWidth={50}
                                      styles={buildStyles({
                                        strokeLinecap: "butt",
                                        pathColor: "white",
                                        trailColor:
                                          theme.colors.ground["gray-500"],
                                      })}
                                      className="w-5 h-5"
                                    />

                                    <div className="text-ground-gray-300">
                                      {retryTimeRemaining}
                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </Popover>
                          )}
                        </div>

                        {canRetryRefund && retryRefundAvailable && (
                          <button
                            id="btn-retry-webhook"
                            name="btn-retry-webhook"
                            data-cy="btn-retry-webhook"
                            type="button"
                            className="bg-ground-yellow-100 px-2 py-1 rounded-full"
                            onClick={() => {
                              setRetryRefundData({
                                event,
                                attempt,
                                refundRequest,
                              });
                              setIsRetryRefundModalOpen(true);
                            }}
                          >
                            <span className="text-center text-ground-blue-100">
                              <IntlMessages id="general.retry" />
                            </span>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </Fragment>
              ))}

              <span className="flex">
                {intl.formatMessage({
                  id: "page.order.refund.request.created",
                })}
              </span>
            </div>
          ),
        },
      ];

      return {
        rowElements: refundRequestElements,
      };
    });

  if (!center) {
    return <></>;
  }

  return (
    <>
      <GridOrderItems
        center={center}
        orders={orders}
        showOrderHeader={showOrderHeader}
        onChangeOrderStatus={(order, orderItem, status) =>
          handleUpdateStatus(
            order,
            orderItem,
            status,
            globalOrder,
            setLoading,
            setRefresh,
            cancelOrderItem,
            cancelOrder,
            updateOrderStatus,
            updateOrderItemStatus,
            intl
          )
        }
        onRefund={(orderToRefund, orderItem, reason) => {
          setDataToRefund({ orderToRefund, orderItem, reason });
          setIsRefundModalOpen(true);
        }}
        match={match}
      />

      {globalOrder && refundRequests.items.length > 0 && (
        <Table
          head={refundRequestsTableHead}
          headBgColor="bg-ground-yellow-100"
          headTextColor="text-neutral-900"
          body={refundRequestsTableBody(center)}
          noDataText=""
        />
      )}

      <ConfirmModal
        isOpen={isRefundModalOpen || isRetryRefundModalOpen}
        toggle={closeModal}
        onRequestClose={closeModal}
        handleConfirm={isRefundModalOpen ? handleRefund : retryRefund}
        content={
          isRefundModalOpen ? (
            <IntlMessages id="page.order.confirm.refund" />
          ) : (
            <IntlMessages id="page.order.confirm.retry.refund" />
          )
        }
        description={displayRefundModalContent()}
        className="max-w-5xl"
        headerClassName="flex items-center justify-center w-full text-lg p-1"
      />
    </>
  );
};

export default GridOrderItemsAndRefunds;
