import { UserModel, userModel } from "./User";
import { UnavailabilityModel, unavailabilityModel } from "./Unavailability";
import { ServiceModel, serviceModel } from "./Service";
import { ProviderModel, providerModel } from "./Provider";
import { ProductModel, productModel } from "./Product";
import { PriceUnitModel, priceUnitModel } from "./PriceUnit";
import { ParticipationModel, participationModel } from "./Participation";
import { GlobalOrderModel, globalOrderModel } from "./GlobalOrder";
import { OrderModel, orderModel } from "./Order";
import { NewsModel, newsModel } from "./News";
import { EventTypeModel, eventTypeModel } from "./EventType";
import { EventModel, eventModel } from "./Event";
import { DaySlotModel, daySlotModel } from "./DaySlot";
import { ClosedDayModel, closedDayModel } from "./ClosedDay";
import { CategoryModel, categoryModel } from "./Category";
import { BackOfficeUserModel, backOfficeUserModel } from "./BackOfficeUser";
import { AttributeModel, attributeModel } from "./Attribute";
import { AttributeKeyModel, attributeKeyModel } from "./AttributeKey";
import { CenterTypeModel, centerTypeModel } from "./CenterType";
import { centerModel, CenterModel } from "./Center";
import { OptionModel, optionModel } from "./Option";
import { SingleProductModel, singleProductModel } from "./SingleProduct";
import { NotificationModel, notificationModel } from "./Notification";
import { AuthorizationModel, authorizationModel } from "./Authorization";
import {
  PriceUnitVariationModel,
  priceUnitVariationModel,
} from "./PriceUnitVariation";
import { ReportingModel, reportingModel } from "./Reporting";

// api
import { CartModel, cartModel } from "./Api/Cart";
import { AccountModel, accountModel } from "./Api/Account";
import { BLOrderModel, blOrderModel } from "./Api/Order";
import { orderItemModel, OrderItemModel } from "./OrderItem";
import { CancellationModel, cancellationModel } from "./Api/Cancellation";
import { incidentModel, IncidentModel } from "./Api/Incidents";
import { visitModel, VisitModel } from "./Api/Visits";
import { paymentMethodsModel, PaymentMethodsModel } from "./Api/PaymentMethods";
import { refundRequestsModel, RefundRequestsModel } from "./Api/RefundRequests";
import { attributeValueModel, AttributeValueModel } from "./Api/Attribute";
import { webhookModel, WebhookModel } from "./Api/Webhooks";
import { documentsModel, DocumentsModel } from "./Api/Documents";
import { refundModel, RefundModel } from "./Api/Refund";

export interface GqlQuery {
  id: string;
  gql?: string;
}

export interface StoreModel {
  user: UserModel;
  unavailability: UnavailabilityModel;
  center: CenterModel;
  centerType: CenterTypeModel;
  service: ServiceModel;
  provider: ProviderModel;
  product: ProductModel;
  priceUnit: PriceUnitModel;
  participation: ParticipationModel;
  globalOrder: GlobalOrderModel;
  order: OrderModel;
  orderItem: OrderItemModel;
  news: NewsModel;
  eventType: EventTypeModel;
  event: EventModel;
  daySlot: DaySlotModel;
  closedDay: ClosedDayModel;
  category: CategoryModel;
  backOfficeUser: BackOfficeUserModel;
  attribute: AttributeModel;
  attributeKey: AttributeKeyModel;
  option: OptionModel;
  singleProduct: SingleProductModel;
  notification: NotificationModel;
  authorization: AuthorizationModel;
  priceUnitVariation: PriceUnitVariationModel;
  reporting: ReportingModel;

  // api
  cart: CartModel;
  account: AccountModel;
  blOrder: BLOrderModel;
  cancellation: CancellationModel;
  incidents: IncidentModel;
  visits: VisitModel;
  paymentMethods: PaymentMethodsModel;
  refundRequestsModel: RefundRequestsModel;
  attributeValue: AttributeValueModel;
  webhooks: WebhookModel;
  documents: DocumentsModel;
  refunds: RefundModel;
}

export const storeModel = {
  user: userModel,
  unavailability: unavailabilityModel,
  center: centerModel,
  centerType: centerTypeModel,
  service: serviceModel,
  provider: providerModel,
  product: productModel,
  priceUnit: priceUnitModel,
  participation: participationModel,
  globalOrder: globalOrderModel,
  order: orderModel,
  orderItem: orderItemModel,
  news: newsModel,
  eventType: eventTypeModel,
  event: eventModel,
  daySlot: daySlotModel,
  closedDay: closedDayModel,
  category: categoryModel,
  backOfficeUser: backOfficeUserModel,
  attribute: attributeModel,
  attributeKey: attributeKeyModel,
  option: optionModel,
  singleProduct: singleProductModel,
  notification: notificationModel,
  authorization: authorizationModel,
  priceUnitVariation: priceUnitVariationModel,
  reporting: reportingModel,

  // api
  cart: cartModel,
  account: accountModel,
  blOrder: blOrderModel,
  cancellation: cancellationModel,
  incidents: incidentModel,
  visits: visitModel,
  paymentMethods: paymentMethodsModel,
  refundRequests: refundRequestsModel,
  attributeValue: attributeValueModel,
  webhooks: webhookModel,
  documents: documentsModel,
  refunds: refundModel,
};
